import React, { useEffect, useRef, useState } from "react"
import mapboxgl from "mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import { theme } from "../theme"

const MapboxGLMap = ({
  height,
  lon = -79.76,
  lat = 43.26,
  zoom = 10,
  markerArray = [],
}) => {
  const styles = {
    width: "100%",
    height: height || "100vh",
    position: "relative",
  }

  const [map, setMap] = useState(null)
  const mapContainer = useRef(null)

  useEffect(() => {
    mapboxgl.accessToken =
      "pk.eyJ1IjoicGV0ZXNtYWx1Y2siLCJhIjoiMENETHdiYyJ9.fV1Yj98QwNt9yfLP-GBLTw"
    const initializeMap = ({ setMap, mapContainer }) => {
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/petesmaluck/cjj00hvib04gs2ss5x6r2wtgz", // stylesheet location
        center: [lon, lat],
        zoom: zoom,
        scrollZoom: false,
      })

      map.on("load", function() {
        map.addSource("places", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: markerArray.map(item => {
              return {
                type: "Feature",
                properties: {
                  description: item.address,
                  URL: `/listing/${item.slug}`,
                },
                geometry: {
                  type: "Point",
                  coordinates: [item.lon, item.lat],
                },
              }
            }),
          },
        })

        // Add a layer showing the places.
        map.addLayer({
          id: "places",
          type: "symbol",
          source: "places",
          layout: {
            "icon-image": "circle-15",
            "icon-allow-overlap": true,
          },
        })

        // Create a popup, but don't add it to the map yet.
        var popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
        })

        map.on("mouseenter", "places", function(e) {
          // Change the cursor style as a UI indicator.
          map.getCanvas().style.cursor = "pointer"

          var coordinates = e.features[0].geometry.coordinates.slice()
          var description = e.features[0].properties.description

          var URL = e.features[0].properties.URL

          // Ensure that if the map is zoomed out such that multiple
          // copies of the feature are visible, the popup appears
          // over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
          }

          // Populate the popup and set its coordinates
          // based on the feature found.
          popup
            .setLngLat(coordinates)
            .setHTML('<h3><a href="' + URL + '">' + description + "</a></h3>")
            .addTo(map)
        })
      })

      var nav = map.addControl(new mapboxgl.NavigationControl())

      map.addControl(nav, "top-right")
    }

    if (!map) initializeMap({ setMap, mapContainer })
  }, [map])

  return <div ref={el => (mapContainer.current = el)} style={styles} />
}

export default MapboxGLMap
