import React, { useState } from "react"
import PropTypes from "prop-types"
import { theme } from "../theme"
import { useWindowSize } from "../util"
import { useModal } from "../context/modal"
import { FaCheckCircle } from "react-icons/fa"
import { IoMdClose } from "react-icons/io"
import styled from "styled-components"
import { StaticQuery } from "gatsby"

const Button = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 34px;
  margin: 20px;
  opacity: 0.6;
  cursor: pointer;
`

const ContactForm = () => {
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")
  const [modal, setModal] = useModal()
  const [formSuccess, setFormSuccess] = useState(false)

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
        name: name,
        email: email,
        phone: phone,
        message: message,
      }),
    })
      .then(() => setFormSuccess(true))
      .catch(error => alert(error))

    e.preventDefault()
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          allWordpressAcfOptions {
            nodes {
              options {
                description
              }
            }
          }
        }
      `}
      render={data => (
        <form name="contact" type="hidden" onSubmit={e => handleSubmit(e)}>
          {!formSuccess && (
            <div style={{ padding: 0, marginBottom: 14 }}>
              <Button
                style={{
                  position: "absolute",
                  right: 18,

                  top: 5,
                  margin: 0,
                  color: theme.colors.black,
                }}
                onClick={() => setModal(false)}
              >
                <IoMdClose />
              </Button>
              <div class="field">
                <label class="label">Name</label>
                <div class="control">
                  <input
                    class="input"
                    type="text"
                    placeholder="Name"
                    name="name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                  />
                </div>
              </div>

              <div class="field">
                <label class="label">Email</label>
                <div class="control">
                  <input
                    class="input"
                    type="email"
                    placeholder="Email"
                    name="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <div class="field">
                <label class="label">Phone Number</label>
                <div class="control">
                  <input
                    class="input"
                    type="tel"
                    placeholder="Phone"
                    name="phone"
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                  />
                </div>
              </div>

              <div class="field">
                <label class="label">Message</label>
                <div class="control">
                  <textarea
                    class="textarea"
                    placeholder=""
                    name="message"
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                  ></textarea>
                </div>
              </div>

              <div class="field is-grouped">
                <div class="control">
                  <button
                    class="button is-link"
                    type="submit"
                    style={{
                      background: theme.colors.main[2],
                      marginBottom: 20,
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    data.allWordpressAcfOptions.nodes[0].options.description,
                }}
              />
            </div>
          )}

          {formSuccess && (
            <div class="field is-grouped">
              <div class="control">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 30,
                  }}
                >
                  <FaCheckCircle
                    style={{
                      fontSize: 26,
                      color: "hsl(141, 71%, 48%)",
                      marginRight: 10,
                    }}
                  />
                  Form Successfully submitted!
                </div>
                <button class="button" onClick={() => setModal(false)}>
                  Go back
                </button>
              </div>
            </div>
          )}
        </form>
      )}
    />
  )
}

export default ContactForm
