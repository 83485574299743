import { Link, graphql, StaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React, { Fragment, useState } from "react"
import styled from "styled-components"
import { theme } from "../theme"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import { useModal } from "../context/modal"
import { Dialog } from "@reach/dialog"
import { useWindowSize } from "../util"
import { IoMdClose } from "react-icons/io"

const Button = styled.div`
  color: ${({ theme }) => theme.colors.text.normal};
  font-size: 34px;
  margin: 20px;
  opacity: 0.6;
  cursor: pointer;
`

const Title = styled.h1`
  font-size: 24px;
  line-height: 34px;
  font-family: ${({ theme }) => theme.fonts.heading};
  color: ${({ theme }) => theme.colors.text.normal};
  margin-bottom: 24px;
`

const Header = ({ siteTitle, data }) => {
  // optionally you can pass options, those are default:
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [modal, setModal] = useModal()
  const [width] = useWindowSize()
  const [openMobileNav, setOpenMobileNav] = useState(false)

  useScrollPosition(({ prevPos, currPos }) => {
    setPosition(currPos)
  })

  return (
    <StaticQuery
      query={graphql`
        query {
          allWordpressWpApiMenusMenusItems(
            filter: { slug: { eq: "main-menu" } }
          ) {
            edges {
              node {
                slug
                name
                items {
                  title
                  object_slug
                }
              }
            }
          }
        }
      `}
      render={data => (
        <Fragment>
          <nav
            className="navbar is-transparent is-fixed-top"
            style={{
              padding: `0 10px`,
              boxShadow:
                position.y < 50 && position.y
                  ? `0 2px 10px rgba(0,0,0,.05)`
                  : "none",
            }}
          >
            <div className="navbar-brand">
              <a className="navbar-item">
                <h2
                  style={{
                    margin: 0,
                    textTransform: "uppercase",
                    fontSize: 18,
                  }}
                >
                  <Link
                    to="/"
                    style={{
                      color: theme.colors.text.normal,
                      fontFamily: theme.fonts.headings,
                      textDecoration: `none`,
                      fontWeight: 700,
                    }}
                  >
                    {siteTitle}
                  </Link>
                </h2>
              </a>
              <div
                className="navbar-burger burger"
                data-target="navbarExampleTransparentExample"
                onClick={() => setOpenMobileNav(!openMobileNav)}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
            <div id="navbarExampleTransparentExample" className="navbar-menu">
              <div className="navbar-start">
                {data.allWordpressWpApiMenusMenusItems.edges[0].node.items.map(
                  item => (
                    <div className="navbar-item">
                      <Link
                        style={{ color: theme.colors.text.normal }}
                        to={`/${item.object_slug}`}
                      >
                        {item.title}
                      </Link>
                    </div>
                  )
                )}
              </div>
              <div className="navbar-end">
                <div className="navbar-item">
                  <div className="field is-grouped">
                    <button
                      className="button"
                      style={{
                        textTransform: "uppercase",
                        height: 40,
                        padding: `5px 40px`,
                      }}
                      onClick={() => setModal(true)}
                    >
                      Contact Nikhil
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </nav>
          {openMobileNav && (
            <Dialog
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: `30px 12px`,
                margin: 0,
              }}
            >
              <Button
                style={{
                  position: "absolute",
                  right: 18,
                  top: 5,
                  margin: 0,
                  color: theme.colors.black,
                }}
                onClick={() => setOpenMobileNav(false)}
              >
                <IoMdClose />
              </Button>
              <Title>Nikhil Bhanwra</Title>

              {data &&
                data.allWordpressWpApiMenusMenusItems.edges[0].node.items.map(
                  item => (
                    <div style={{ marginBottom: 20 }}>
                      <Link
                        style={{ color: theme.colors.text.normal }}
                        to={`/${item.object_slug}`}
                      >
                        {item.title}
                      </Link>
                    </div>
                  )
                )}
              <div className="field is-grouped">
                <button
                  className="button"
                  style={{
                    textTransform: "uppercase",
                    height: 40,
                    padding: `5px 40px`,
                  }}
                  onClick={() => {
                    setModal(true)
                    setOpenMobileNav(false)
                  }}
                >
                  Contact Nikhil
                </button>
              </div>
              <ul style={{ textAlign: "center", marginTop: 14 }}>
                <li>
                  <a
                    href="mailto:bhanwra@judymarsales.com"
                    style={{ color: theme.colors.black }}
                  >
                    bhanwra@judymarsales.com
                  </a>
                </li>
                <br />
                <li>
                  <a
                    href="tel:289-260-7554"
                    style={{ color: theme.colors.black }}
                  >
                    289-260-7554
                  </a>
                </li>
              </ul>
            </Dialog>
          )}
        </Fragment>
      )}
    />
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `Nikhil Bhanwra`,
}

export default Header
