import styled from "styled-components"

export const theme = {
  space: ["0", "4px", "8px", "16px", "32px", "64px", "128px", "256px", "512px"],
  fontSizes: [
    "12px",
    "14px",
    "16px",
    "20px",
    "24px",
    "36px",
    "48px",
    "80px",
    "96px",
  ],
  fontWeights: ["400", "700"],
  lineHeights: {
    solid: "1",
    title: "1.25",
    copy: "1.5",
  },
  letterSpacings: {
    normal: "normal",
    tracked: "0.1em",
    tight: "-0.05em",
    mega: "0.25em",
  },
  fonts: {
    text: `'Gothic A1', sans-serif`,
    heading: "Amiri",
    // bold: 'ProximaNova-Semibold',
  },
  borders: [
    "0",
    "1px solid",
    "2px solid",
    "4px solid",
    "8px solid",
    "16px solid",
    "32px solid",
  ],
  radii: ["0", "2px", "4px", "8px", "16px", "9999px", "100%"],
  widths: ["16px", "32px", "64px", "128px", "256px"],
  heights: ["16px", "32px", "64px", "128px", "256px"],
  colors: {
    black: "#1d1c1c",
    white: "#fffbf2",
    transparent: "transparent",
    main: ["#fffbf2", "#f65b40", "#002653"],
    secondary: [
      "#f8f3eb",
      "#ccc9c2",
      "#f5f5f2",
      "rgba(191,222,220,.17)",
      "#f65b40",
      "#e6b022",
      "#f9f6ed",
    ],
    greyscale: [
      "#313541",
      "#354052",
      "#667587",
      "#8d969f",
      "#DFE6EE",
      "#F4F7FA",
      "#FBFDFF",
    ],
    text: {
      light: "#727272",
      normal: "#1d1c1c",
      highlight: "#3898ec",
      error: "#E34C4C",
    },
  },
}

export const shadowMixin = () => `
  shadow-color: black;
  shadow-offset: 0px 1px;
  shadow-opacity: 0.2;
  shadow-radius: 6px;
  elevation: 5;
`

// export const NormalText = styled.Text`
//   font-family: ${({ theme }) => theme.fonts.normal};
// `;
// export const NormalTextInput = styled.TextInput`
//   font-family: ${({ theme }) => theme.fonts.normal};
// `;

// export const BoldText = styled.Text`
//   font-family: ${({ theme }) => theme.fonts.bold};
// `;
// export const BoldTextInput = styled.TextInput`
//   font-family: ${({ theme }) => theme.fonts.bold};
// `;
