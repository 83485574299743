import { Link, StaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { theme } from "../theme"
import { FaInstagram } from "react-icons/fa"
import { useModal } from "../context/modal"
import { Dialog } from "@reach/dialog"
import ContactForm from "./contactForm"
import { useWindowSize } from "../util"

const Title = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-family: ${({ theme }) => theme.fonts.text};
  font-weight: 700;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: ${({ theme }) => theme.space[5]};
  line-height: 1.2;
  letter-spacing: 2px;
`

const Subtitle = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes[1]};
  font-family: ${({ theme }) => theme.fonts.text};
  font-weight: 400;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.text.light};
  margin-bottom: ${({ theme }) => theme.space[4]};
  line-height: 1.2;
`

const Footer = ({ siteTitle }) => {
  const [modal] = useModal()
  const [width] = useWindowSize()

  return (
    <StaticQuery
      query={graphql`
        query {
          allWordpressWpApiMenusMenusItems(
            filter: { slug: { eq: "main-menu" } }
          ) {
            edges {
              node {
                slug
                name
                items {
                  title
                  object_slug
                }
              }
            }
          }
        }
      `}
      render={data => (
        <footer
          className="footer"
          style={{
            background: theme.colors.main[2],
            color: theme.colors.white,
          }}
        >
          <div className="container" style={{ maxWidth: 1064 }}>
            <Title>Nikhil Bhanwra</Title>
            <div className="columns">
              <div className="column">
                <Subtitle>Site</Subtitle>
                <ul>
                  {data.allWordpressWpApiMenusMenusItems.edges[0].node.items.map(
                    item => (
                      <li>
                        <Link
                          style={{ color: theme.colors.white }}
                          to={`/${item.object_slug}`}
                        >
                          {item.title}
                        </Link>
                      </li>
                    )
                  )}
                </ul>
              </div>
              <div className="column">
                <Subtitle>Contact</Subtitle>
                <ul>
                  <li>
                    <a
                      href="mailto:bhanwra@judymarsales.com"
                      style={{ color: theme.colors.white }}
                    >
                      bhanwra@judymarsales.com
                    </a>
                  </li>
                  <li>
                    <a
                      href="tel:289-260-7554"
                      style={{ color: theme.colors.white }}
                    >
                      289-260-7554
                    </a>
                  </li>
                </ul>
              </div>
              <div className="column">
                <Subtitle>Social</Subtitle>
                <a
                  target="_blank"
                  href="https://www.instagram.com/nikhil.bhanwra/"
                  style={{ color: theme.colors.white }}
                >
                  <FaInstagram style={{ fontSize: 20 }} />
                </a>
              </div>
              <div className="column is-one-third">
                <img src="https://www.judymarsales.com/images/logo-Judy-Marsales-sm.png" />
              </div>
              <div className="column">
                <img src="https://www.judymarsales.com/images/anniversary-seal.png" />
              </div>
            </div>
            <br />
            <br />
            <br />
            <div className="columns">
              <div className="column is-one-third">
                <Subtitle>© {new Date().getFullYear()} Nikhil Bhanwra</Subtitle>
              </div>
              <div className="column"></div>
              <div className="column"></div>
              <div className="column"></div>
            </div>
          </div>
          {modal && (
            <Dialog
              style={{
                maxWidth: width > 768 ? 400 : 10000,
              }}
            >
              <ContactForm />
            </Dialog>
          )}
        </footer>
      )}
    />
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: `Nikhil Bhanwra`,
}

export default Footer
