import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import { useWindowSize } from "../util"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import { theme } from "../theme"
import { FaBath, FaBed } from "react-icons/fa"
import { AiOutlineHome } from "react-icons/ai"

import MapboxGLMap from "../components/map"
import { Waypoint } from "react-waypoint"

const Container = styled.div`
  max-width: none;
  width: 100%;
`

const Banner = styled.div`
  height: auto;
  background-color: hsla(0, 0%, 76.9%, 0.15);
  margin-bottom: 24px;
  padding: 16px 24px;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.h1`
  font-size: 24px;
  line-height: 34px;
  font-family: ${({ theme }) => theme.fonts.heading};
  color: ${({ theme }) => theme.colors.text.normal};
`

const Subtitle = styled.h1`
  font-size: 13px;
  line-height: 19px;
  font-family: ${({ theme }) => theme.fonts.text};
  color: ${({ theme }) => theme.colors.black};
`

const Details = styled.div`
  font-size: 13px;
  font-family: ${({ theme }) => theme.fonts.text};
  line-height: 19px;
  color: #727272;
`

const TextContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(91.66659% - 16px);
  max-width: calc(91.66659% - 16px);
  justify-content: flex-start;
`

const SectionAlternateTitle = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-family: ${({ theme }) => theme.fonts.text};
  font-weight: 700;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.text.normal};
  margin-bottom: ${({ theme }) => theme.space[4]};
  line-height: 1.2;
`
const Img = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  cursor: pointer;
`

const ImgSubtitle = styled.p`
  font-family: ${({ theme }) => theme.fonts.text};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  color: ${({ theme }) => theme.colors.text.normal};
`

const SquareImgContainer = styled.div`
  width: 100%;
  height: 420px;
  overflow: hidden;
  position: relative;
  object-fit: cover;
  margin-bottom: 8px;
  font-family: ${({ theme }) => theme.fonts.text};
`

const CrossedOutText = styled.div`
  font-family: ${({ theme }) => theme.fonts.text};
  text-decoration: line-through;
  color: ${({ theme }) => theme.colors.text.light};
`

const ListingsTemplate = ({ data }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0)
  const [width] = useWindowSize()
  const [mapPosition, setMapPostion] = useState(true)

  return (
    <Layout>
      <SEO
        title={"Listings"}
        description={
          "Nikhil Bhanwra, nikhilbhanwra.com, Judy Marsales, Hamilton, Ontario, Burlington, Real Estate Listings, HamOnt"
        }
      />
      <Container>
        <div className="columns is-gapless">
          <div className={width > 1260 ? `column is-three-fifths` : "column"}>
            <section className="section" style={{ paddingTop: 0 }}>
              <Banner>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <AiOutlineHome style={{ marginRight: 24, fontSize: 24 }} />
                  <div>
                    <Subtitle>
                      <b>Listings</b>
                    </Subtitle>
                  </div>
                </div>
                <div></div>
              </Banner>
              <div className="columns is-multiline">
                {data.allWordpressWpListing.nodes.map((item, i) => (
                  <div className="column is-half" key={i}>
                    <Link
                      to={`/listing/${item.slug}`}
                      style={{ color: theme.colors.text.normal }}
                    >
                      <SquareImgContainer>
                        <Img src={item.acf.main_image.source_url} />
                        <div
                          style={{
                            position: "absolute",
                            top: 8,
                            right: 8,
                            padding: `4px 12px`,
                            backgroundColor: "#fffbf2",
                            borderRadius: 5,
                          }}
                        >
                          {item.acf.price}
                          <br />
                          <CrossedOutText>
                            {item.acf.previous_price}
                          </CrossedOutText>
                        </div>
                      </SquareImgContainer>
                      <Details>
                        {item.acf.building.bedrooms}{" "}
                        {item.acf.building.bedrooms === "1"
                          ? "Bedroom"
                          : "Bedrooms"}
                        , {item.acf.building.bathrooms}{" "}
                        {item.acf.building.bathrooms === "1"
                          ? "Bathroom"
                          : "Bathrooms"}
                        , {item.acf.building.type_of_home}
                      </Details>
                      <Title>{item.acf.tag_line}</Title>
                      <Subtitle>{item.acf.listing_city}</Subtitle>
                    </Link>
                  </div>
                ))}
              </div>
            </section>
          </div>
          {width > 1260 && (
            <div className="column is-two-fifths">
              <div
                style={{
                  position: mapPosition ? "fixed" : null,
                  bottom: 0,
                  height: "100%",
                  width: "100vh",
                  display: "flex",
                  justifyContent: "flex-end",
                  flexDirection: "column",
                }}
              >
                <MapboxGLMap
                  markerArray={data.allWordpressWpListing.nodes.map(item => {
                    return {
                      lon: item.acf.location.longitude,
                      lat: item.acf.location.latitude,
                      address: item.acf.listing_address,
                      image: item.acf.main_image.source_url,
                      slug: item.slug,
                    }
                  })}
                  zoom="10"
                />
              </div>
            </div>
          )}
        </div>
      </Container>
      <Waypoint
        onLeave={({ previousPosition, currentPosition, event }) => {
          if (currentPosition === "below") {
            // setShowSideNav(!showSideNav)
            setMapPostion(true)
          }
        }}
        onEnter={({ previousPosition, currentPosition, event }) => {
          if (previousPosition === "below") {
            // setShowSideNav(!showSideNav)
            setMapPostion(false)
          }
        }}
      />
    </Layout>
  )
}
export default ListingsTemplate

export const query = graphql`
  query {
    allWordpressWpListing(sort: { fields: date, order: DESC }) {
      nodes {
        slug
        acf {
          tag_line
          price
          previous_price
          listing_address
          listing_city
          building {
            bathrooms
            bedrooms
            type_of_home
          }
          main_image {
            source_url
          }
          location {
            latitude
            longitude
          }
          seo_description
        }
      }
    }
  }
`
